

































import { Component } from 'vue-property-decorator';
import BaseComponent from '../components/BaseComponent';
import FlatfileImporter from 'flatfile-csv-importer';
import ErrorComponent from '../components/ErrorComponent.vue';
import SpinnerComponent from '../components/SpinnerComponent.vue';
import { importBulkRecords, getSchemasByType, getBulkImportStatus } from '../store/rest.service';
import { ReferenceData, ShortRecord, TieredReferenceData, CategoryReferenceData, DiversityRefDataValue } from '../store/models';
import { validateSchema, isPropertyRequiredInSchema, getDescriptionFromSchema } from '../lib/json-schema';
import ReconnectingWebSocket from 'reconnecting-websocket';
import WS from 'ws';
import { toIsoDateString } from '../lib/datetime';
import { saveAs } from 'file-saver';
import * as _ from 'lodash';

// TODO: Put the license into the config.
const LICENSE_KEY: string = '40707389-4a1f-4b13-838b-5c04a44af4ae';
const rwsOptions: any = {
    WebSocket: WS, // custom WebSocket constructor
    connectionTimeout: Infinity,
    maxRetries: Infinity,
    maxEnqueuedMessages: Infinity,
};


@Component({
  components: {
    ErrorComponent,
    SpinnerComponent,
  },
})
export default class ImportComponent extends BaseComponent {
  public importErrors: Error[] = [];
  public bulkImportResponse: any;
  public toIsoDateString = toIsoDateString;
  private results: string = '';
  private friendlyErrorMessages: string[] = [];
  private completionMessage: string = '';
  private isLaunched: boolean = false;
  private isLaunching: boolean = false;
  private isImporting: boolean = false;
  private isProcessing: boolean = false;
  private isProcessingMessage: boolean = false;
  private isSchemaLoading: boolean = false;
  private hasImportOccurred: boolean = false;
  private schemas: any[] = [];
  private requiredFields: string[] = [];
  private uploadOperation: string = '';
  private uploadProgress: number = 0;
  private uploadState: string = '';
  private wsLocation: string = '';
  private importId: string = '';
  private importer!: FlatfileImporter;
  private socket!: ReconnectingWebSocket;

  public showButtons(): boolean {
    return !(!this.isLaunched || this.isLaunching || this.isImporting) && this.hasImportOccurred;
  }

  public resultsAvailable(): boolean {
    return this.results !== '';
  }

  public launchIfLoaded(): void {
    if (this.isFieldsLoaded() && !this.isLaunched && !this.isLaunching && !this.isSchemaLoading) {
      this.isLaunching = true;
      this.launch();
    }
  }

  public getOptionsFromTieredRefData(refData: TieredReferenceData|null): any[] {
    if (refData) {
      return _.flatten(refData.refData.values.map<any>((i) => i.subCategories.map<any>((x) => ({ value: x.categoryCode + ';' + x.code, label: x.category + ' > ' + x.name }))));
    } else {
      return [];
    }
  }

  public getOptionsFromCategoryRefData(refData: CategoryReferenceData|null): any[] {
    if (refData) {
      return refData.refData.values.map<any>((i) => ({ value: i.categoryCode + ';' + i.code, label: i.category + ' > ' + i.name }));
    } else {
      return [];
    }
  }

  public getOptionsFromRefData(refData: ReferenceData|null): any[] {
    if (refData) {
      return refData.refData.values.map<any>((i) => ({ value: i.code, label: i.name }));
    } else {
      return [];
    }
  }

  public getOptionsFromDiversityRefData(refData: DiversityRefDataValue[]): any[] {
    if (refData) {
      // Commented out as data model changed.
      // return refData.map<any>((i) => ({ value: i.code, label: i.name }));
      return [];
    } else {
      return [];
    }
  }

  public getOptionsFromShortRecords(shortRecords: ShortRecord[]|null): any[] {
    if (shortRecords) {
      return shortRecords.map<any>((i: any) => (i.common ? { value: i.common.identifier, label: i.common.displayName } : { value: i.identifier, label: i.displayName }));
    } else {
      return [];
    }
  }

  public getRefDataCodeFromName(refData: ReferenceData|null, name: string): string|null {
    if (refData) {
      const result = refData.refData.values.filter((i) => i.name === name);
      return result.length > 0 ? result[0].code : null;
    } else {
      return null;
    }
  }

  public getTieredRefDataCodeFromName(refData: TieredReferenceData|null, name: string): string|null {
    if (refData) {
      const options = this.getOptionsFromTieredRefData(refData);
      const result = options.filter((i) => i.label === name);
      return result.length > 0 ? result[0].value : null;
    } else {
      return null;
    }
  }

  public getCategoryRefDataCodeFromName(refData: CategoryReferenceData|null, name: string): string|null {
    if (refData) {
      const options = this.getOptionsFromCategoryRefData(refData);
      const result = options.filter((i) => i.label === name);
      return result.length > 0 ? result[0].value : null;
    } else {
      return null;
    }
  }

  public getCategoryRefDataCodeFromCombinedValues(combinedValue: string|null): string {
    if (combinedValue !== undefined) {
      const combinedValueArray = (combinedValue as string).split(';');
      return combinedValueArray[1];
    } else {
      return '';
    }
  }

  public getShortRecordIdentifierFromDisplayName(shortRecords: ShortRecord[]|null, displayName: string): string|null|undefined {
    if (shortRecords) {
      const result = shortRecords.filter((i) => i.displayName === displayName);
      return result.length > 0 ? result[0].identifier : null;
    } else {
      return null;
    }
  }

  public isPropertyRequired(propertyName: string): boolean {
    let isRequired: boolean = false;
    for (const schema of this.schemas) {
      isRequired = isPropertyRequiredInSchema(schema.schema, propertyName);
      if (isRequired) {
        break;
      }
    }
    return isRequired;
  }

  public getDescriptionFromSchema(propertyName: string): string {
    let description: string = '';
    for (const schema of this.schemas) {
      description = getDescriptionFromSchema(schema.schema, propertyName);
      if (description !== '') {
        break;
      }
    }
    return description;
  }

  public getField(propertyName: string, propertyLabel: string, options: any[] = [], isBoolean: boolean = false, forceRequired: boolean = false) {
    return {
      label: propertyLabel,
      key: propertyName,
      validators: (this.isPropertyRequired(propertyName) || forceRequired) ? [ {validate: 'required'} ] : [],
      type: options.length > 0 ? 'select' : isBoolean ? 'checkbox' : 'string',
      options,
      description: this.getDescriptionFromSchema(propertyName),
    };
  }

  public loadSchemas(): void {
    this.isSchemaLoading = true;
    const type = this.getSchemaType();
    getSchemasByType(type).then((response) => {
      for (const schemaName of this.getSchemas()) {
        const schema = response[schemaName];
        if (schema) {
          this.schemas.push({ id: type + schemaName, schema });
        }
      }
    }).catch((error) => {
      this.importErrors.push(error);
      this.bulkImportResponse = error.response;
    }).finally(() => {
      this.isSchemaLoading = false;
      this.launchIfLoaded();
    });
  }

  public cleanseNumericField(fieldValue: string): any {
    if (fieldValue) {
      fieldValue = this.cleanseNumeric(fieldValue);
      return Number.isNaN(+fieldValue) ? fieldValue : +fieldValue;
    } else {
      return 0;
    }
  }

  public cleanseCurrencyField(fieldValue: string): any {
    if (fieldValue) {
      fieldValue = this.cleanseNumeric(fieldValue);
      fieldValue = fieldValue.replace(/(^\$)|(,)/g, ''); // Remove dollar sign from the beginning of the string and commas.
      return Number.isNaN(+fieldValue) ? fieldValue : +fieldValue;
    } else {
      return 0;
    }
  }

  public cleanseBooleanField(fieldValue: string): boolean {
    if (fieldValue && typeof fieldValue === 'string') {
      fieldValue = fieldValue.trim().toLowerCase(); // Trim leading and trailing spaces, lower-case.
      return (fieldValue === 'yes' || fieldValue === 'true' || fieldValue === 'on'  || fieldValue === '1');
    } else if (fieldValue && typeof fieldValue === 'boolean') {
      return fieldValue;
    } else {
      return false;
    }
  }

  public getDefaultFinishedUrl(): string {
    return '/bulk-import';
  }

  protected getFields(): any[] {
    return [];
  }

  protected getType(): string {
    return '';
  }

  protected getSchemaType(): string {
    return '';
  }

  protected getRecordType(): string {
    return '';
  }

  protected isFieldsLoaded(): boolean {
    return false;
  }

  protected getConvertedJsonRecord(record: any): any {
    return;
  }

  protected getSchemas(): any[] {
    return [];
  }

  protected formatRecordForImport(record: any): any {
    return record;
  }

  protected getFinishedUrl(): string {
    return this.getDefaultFinishedUrl();
  }

  private cleanseNumeric(fieldValue: string): string {
    fieldValue = fieldValue.trim(); // Trim leading and trailing spaces.
    fieldValue = fieldValue === '-' ? '0' : fieldValue; // Replace - with 0 (which is something excel can do).
    return fieldValue;
  }

  private exportResults() {
    const blob = new Blob([this.results], {type: 'text/plain;charset=utf-8'});
    saveAs(blob, 'import-results.json');
  }

  private continueToFinishedUrl() {
    const finishedUrl: string = this.getFinishedUrl();
    if (finishedUrl !== '') {
      this.$router.push({ path: finishedUrl });
    }
  }

  private relaunch(): void {
    this.isLaunched = false;
    this.launchIfLoaded();
  }

  private getErrorKey(error: any) {
    // TODO: What else can come back here? Can we write something generic?
    return error.params ?
              error.params.missingProperty ? error.params.missingProperty :
              error.dataPath ? error.dataPath.substr(error.dataPath.lastIndexOf('.') + 1).replace('[0]', '') : ''
            : '';
  }

  private formatError(errors: any) {
    const out = {};
    errors.map((error) =>
      out[this.getErrorKey(error)] = {
          info: [
          {
            message: error.message,
            level: 'error',
          },
        ],
      },
    );
    return out;
  }

  private formatCallbackErrors(errors: any) {
    // console.log(JSON.stringify(errors));
    const flattenedErrors = errors.flatMap((error) => this.formatError(error));
    return flattenedErrors[0];
  }

  private removeEmptyJsonProperties(record: any) {
    for (const prop in record) {
      if (typeof record[prop] === 'string' && record[prop] === '') {
        delete record[prop];
      } else if (typeof record[prop] === 'object') {
        this.removeEmptyJsonProperties(record[prop]);
      }
    }
    return record;
  }

  private recordHook(record: any, index: number) {
    return this.formatCallbackErrors(validateSchema(this.schemas, this.removeEmptyJsonProperties(this.getConvertedJsonRecord(record))));
  }

  private recordContainsValue(record: any) {
    for (const prop in record) {
      if (record[prop] !== null && record[prop] !== undefined && record[prop] !== '' && record[prop] !== false) {
        return true;
      }
    }
    return false;
  }

  private filterNullRecords(records: any[]) {
    const filteredRecords: any[] = [];
    // If there is a property which is not null, empty string or undefined then return it.
    records.map<any>((record) => { if (this.recordContainsValue(record)) { filteredRecords.push(record); } });
    return filteredRecords;
  }

  private performImport(records: any[]) {
    this.friendlyErrorMessages = [];
    this.hasImportOccurred = true;
    this.importer.displayLoader('Your import is being submitted please wait.');
    this.isImporting = true;
    this.completionMessage = '';
    this.results = '';
    this.uploadProgress = 0;
    this.uploadState = '';
    const filteredRecords: any[] = this.filterNullRecords(records);
    // filteredRecords.map<any>((record) => (console.log(JSON.stringify(record))));
    const formattedRecords = filteredRecords.map<any>((record) => (this.removeEmptyJsonProperties(this.getConvertedJsonRecord(record))));
    importBulkRecords(formattedRecords, this.getRecordType() === '' ? this.getSchemaType() : this.getRecordType()).then((response) => {
      //Web Socket to report on job status.
      this.wsLocation = process.env.VUE_APP_QS_URL.replace('https://', 'wss://') + response;
      this.importId = (response as string).replace('/record/bulk/id/', '').replace('/status/ws', '');
      this.socket = new ReconnectingWebSocket(this.wsLocation);
      this.socket.onerror = this.socketError;
      this.socket.onmessage = this.socketMessage;
      this.importer.close();
    }).catch((error) => {
      this.importErrors.push(error);
      this.processFailureResults(error.response);
    }).finally(() => {
      this.isImporting = false;
      this.isProcessing = true;
    });
  }

  private processSuccessResults(message: any) {
    this.bulkImportResponse = message.output;
    this.completionMessage = message.completionMessage;
    this.results = JSON.stringify(this.bulkImportResponse, null, 2);
  }

  private processFailureResults(message: any) {
    this.bulkImportResponse = message;
    this.completionMessage = this.bulkImportResponse.completionMessage;
    this.results = JSON.stringify(message, null, 2);
    this.setFriendlyErrorMessages(message);
  }

  private pushErrorMessage(msg: string|undefined|null) {
    if (msg !== undefined && msg) {
      if (this.friendlyErrorMessages.indexOf(msg) === -1) {
        this.friendlyErrorMessages.push(msg);
      }
    }
  }

  private setFriendlyErrorMessages(results: any): void {
    try {
      if (results.output) {
        this.friendlyErrorMessages = [];
        for (const err of results.output.validationErrors) {
          if (err.validationReport) {
            if (err.validationReport.loadResults) {
              for (const jsonValidationResult of err.validationReport.jsonValidationResults) {
                if (jsonValidationResult.errors) {
                  for (const error of jsonValidationResult.errors) {
                    this.pushErrorMessage(error.message);
                  }
                }
              }
              if (err.validationReport.loadResults.errors) {
                for (const errMsg of err.validationReport.loadResults.errors) {
                  if (errMsg.error) {
                    const parsedError = JSON.parse(errMsg.error.text);
                    for (const parsedErrMsg of parsedError.errors) {
                      let msg: string = '';
                      if (parsedErrMsg.validationError) {
                        msg = parsedErrMsg.validationError.message;
                      } else if (parsedErrMsg.UnexpectedException) {
                        msg = parsedErrMsg.UnexpectedException.message;
                      }
                      this.pushErrorMessage(msg);
                    }
                  } else {
                    let msg: string = '';
                    if (errMsg.UnexpectedException) {
                      msg = errMsg.UnexpectedException.message;
                    } else if (errMsg.missingReferenceTarget) {
                      msg = errMsg.missingReferenceTarget.message;
                    }
                    this.pushErrorMessage(msg);
                  }
                }
              }
            }
          }
        }
      }
    } catch (ex) {
      console.log(JSON.stringify(ex));
    }
  }

  private launch() {
    const flatFileConfig: any = {
      config: {
        fields: this.getFields(),
        type: this.getType(),
        allowInvalidSubmit: false,
        managed: true,
        allowCustom: true,
        disableManualInput: false,
        preloadRowCount: 35000,
      },
    };
    FlatfileImporter.setVersion(2);
    this.importer = new FlatfileImporter(LICENSE_KEY, flatFileConfig.config);
    const userId = this.currentUser.userinfo.identifier;
    const name = this.currentUser.userinfo.displayName;
    this.importer.setCustomer({ userId, name });
    this.importer.registerRecordHook(this.recordHook);
    this.isLaunched = true;
    this.importer
      .requestDataFromUser()
      .then((results) => {
        // Perform the import using the validated results.
        this.performImport(results.validData);
      })
      .catch((error) => {
        if (error) {
          console.log(JSON.stringify(error));
          this.importErrors.push(error);
        }
      })
      .finally(() => {
        this.isLaunching = false;
        if (!this.hasImportOccurred) {
          this.continueToFinishedUrl();
        }
      });
    }

    private socketError(error: any) {
      if (this.isProcessing && !this.isProcessingMessage) {
        this.isProcessingMessage = true;
        this.getBulkImportStatus();
      }
    }

    // Update the percentage progress of the import.
    private socketMessage(event: any) {
      if (this.isProcessing && !this.isProcessingMessage) {
        this.isProcessingMessage = true;
        let message: string = '';
        try {
          message = JSON.parse(event.data);
          this.processSocketMessage(message);
        } catch (ex) {
          // Truncated message so just get it from the REST endpoint.
          this.getBulkImportStatus();
        }
      }
    }

    private getBulkImportStatus() {
      getBulkImportStatus(this.importId).then((message) => {
        try {
          this.processSocketMessage(message);
        } catch (ex) {
          // Commented out as compile error.
          // this.importErrors.push(ex);
          this.closeSocket();
        }
      });
    }

    private processSocketMessage(message: any) {
      try {
        if (this.isProcessing) {
          this.uploadOperation = message.operation;
          this.uploadProgress = message.percentage;
          this.uploadState = message.state;
          if (this.uploadState === 'aborted') {
            this.processFailureResults(message);
            this.closeSocket();
          } else if (this.uploadProgress === 100) {
            this.processSuccessResults(message);
            this.closeSocket();
          }
        }
      } catch { }
      this.isProcessingMessage = false;
    }

    private closeSocket() {
      try {
        this.isProcessing = false;
        this.isProcessingMessage = false;
        this.socket.close();
      } catch { }
    }
}
