import Ajv from 'jonscode.ajv';

export function getSchemaValidator(schema: any): any {
  const ajv: any = new Ajv({ allErrors: true, verbose: false, multipleOfPrecision: 6 });
  return ajv.compile(schema.schema, schema.id);
}

export function validateSchema(schemas: any[], data: any): any {
  const errors: any[] = [];
  for (const schema of schemas) {
    const validator = getSchemaValidator(schema);
    const valid = validator(data);
    if (!valid) {
      errors.push(validator.errors);
    }
  }
  return errors;
}

export function getDescriptionFromSchema(schema: any, propertyName: string): string {
  let description: string = '';
  if (schema.properties) {
    return getDescriptionFromSchema(schema.properties, propertyName);
  } else if (schema.default) {
    return getDescriptionFromSchema(schema.default, propertyName);
  } else {
    if (typeof schema === 'object') {
      for (const property in schema) {
        if (schema.hasOwnProperty(property)) {
          if (property === propertyName && schema[property].description) {
            description = schema[property].description;
          } else {
            description = getDescriptionFromSchema(schema[property], propertyName);
          }
          if (description !== '') {
            return description;
          }
        }
      }
    }
    return description;
  }
}

// Recursive function that searches for an array called 'required', if that array contains the propertyName then true is returned, otherwise false.
export function isPropertyRequiredInSchema(schema: any, propertyName: string, ignoreProperties: boolean = false): boolean {
  let isRequired: boolean = false;
  isRequired = schema.required ? schema.required.indexOf(propertyName) > -1 : false;
  if (isRequired) {
    return isRequired;
  } else {
      if (schema.properties && !ignoreProperties) {
        return isPropertyRequiredInSchema(schema.properties, propertyName, schema.properties.properties ? true : false);
      } else if (schema.default) {
        return isPropertyRequiredInSchema(schema.default, propertyName);
      } else {
        if (typeof schema === 'object') {
          for (const property in schema) {
            if (schema.hasOwnProperty(property) ) {
              isRequired = isPropertyRequiredInSchema(schema[property], propertyName);
              if (isRequired) {
                return isRequired;
              }
            }
          }
        }
        return isRequired;
      }
    }
}
